// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { GoldBuyModel } from '../_models/goldbuy.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class GoldBuyService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new goldbuy to the server
	createGoldBuy(goldbuy: GoldBuyModel): Observable<GoldBuyModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<GoldBuyModel>(BASE_URL, goldbuy, { headers: httpHeaders});
	}

	// READ
	getAllGoldBuys(): Observable<GoldBuyModel[]> {
		return this.http.get<GoldBuyModel[]>(BASE_URL);
	}

	getGoldBuyById(goldbuyId: number): Observable<GoldBuyModel> {
		return this.http.get<GoldBuyModel>(BASE_URL + `/${goldbuyId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findGoldBuys(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'partner/goldBuy';
		
    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the goldbuy on the server
	updateGoldBuy(goldbuy: GoldBuyModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, goldbuy, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForGoldBuy(goldbuys: GoldBuyModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			goldbuysForUpdate: goldbuys,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the goldbuy from the server
	deleteGoldBuy(goldbuyId: number): Observable<GoldBuyModel> {
		const url = `${BASE_URL}/${goldbuyId}`;
		return this.http.delete<GoldBuyModel>(url);
	}

	deleteGoldBuys(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteGoldBuys';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { goldbuyIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}

  // Download
  downloadGoldBuy(beginDate: string, endDate: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'partner/export/goldbuy'+'?beginDate=' + beginDate + '&endDate=' + endDate;    
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }
}
