import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { GoldRedeemService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    GoldRedeemActionTypes,
    GoldRedeemsPageRequested,
    GoldRedeemsPageLoaded,
    ManyGoldRedeemsDeleted,
    OneGoldRedeemDeleted,
    GoldRedeemActionToggleLoading,
    GoldRedeemsPageToggleLoading,
    GoldRedeemUpdated,
    GoldRedeemsStatusUpdated,
    GoldRedeemCreated,
    GoldRedeemOnServerCreated
} from '../_actions/goldredeem.actions';
import { of } from 'rxjs';

@Injectable()
export class GoldRedeemEffects {
    showPageLoadingDistpatcher = new GoldRedeemsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new GoldRedeemActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new GoldRedeemActionToggleLoading({ isLoading: false });

    @Effect()
    loadGoldRedeemsPage$ = this.actions$.pipe(
        ofType<GoldRedeemsPageRequested>(GoldRedeemActionTypes.GoldRedeemsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goldredeemsService.findGoldRedeems(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new GoldRedeemsPageLoaded({
                goldredeems: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteGoldRedeem$ = this.actions$
        .pipe(
            ofType<OneGoldRedeemDeleted>(GoldRedeemActionTypes.OneGoldRedeemDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldredeemsService.deleteGoldRedeem(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteGoldRedeems$ = this.actions$
        .pipe(
            ofType<ManyGoldRedeemsDeleted>(GoldRedeemActionTypes.ManyGoldRedeemsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldredeemsService.deleteGoldRedeems(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateGoldRedeem$ = this.actions$
        .pipe(
            ofType<GoldRedeemUpdated>(GoldRedeemActionTypes.GoldRedeemUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldredeemsService.updateGoldRedeem(payload.goldredeem);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateGoldRedeemsStatus$ = this.actions$
        .pipe(
            ofType<GoldRedeemsStatusUpdated>(GoldRedeemActionTypes.GoldRedeemsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldredeemsService.updateStatusForGoldRedeem(payload.goldredeems, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createGoldRedeem$ = this.actions$
        .pipe(
            ofType<GoldRedeemOnServerCreated>(GoldRedeemActionTypes.GoldRedeemOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldredeemsService.createGoldRedeem(payload.goldredeem).pipe(
                    tap(res => {
                        this.store.dispatch(new GoldRedeemCreated({ goldredeem: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private goldredeemsService: GoldRedeemService, private store: Store<AppState>) { }
}
