<kt-portlet>
  <!-- PORTLET LOADING | Binded to TABLE Datasource -->
  <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
  <kt-portlet-header [title]="'Gold Redeem List'" [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
    <ng-container ktPortletTools>
      <mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button-mt-4"></mat-spinner-button>
      <!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
      <!-- mat-raised-button | Rectangular contained button w/ elevation  -->
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

	<kt-portlet-body>
		
    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-4 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search goldredeem" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> in all fields
							</mat-hint>
						</mat-form-field>
					</div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput
                  placeholder="Choose a date"
                  [satDatepicker]="picker"
                  [(value)]="dateRange"
                  (dateChange)="onDateChange($event)">
              <sat-datepicker
                  #picker
                  [rangeMode]="true"
                  orderPeriodLabel="month"
                  [closeAfterSelection]="true">>
              </sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </mat-form-field>
          </div>
				</div>
			</div>
		</div>

		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="asc"
				matSortDisableClear>

				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
					<mat-cell *matCellDef="let goldredeem">{{goldredeem.name}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="email">
					<mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Email</mat-header-cell>
					<mat-cell *matCellDef="let goldredeem" class="email-cell">
						<a href="mailto:{{goldredeem.email}}" class="kt-link">{{goldredeem.email}}</a>
					</mat-cell>
				</ng-container>

        <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Unit</mat-header-cell>
          <mat-cell *matCellDef="let goldredeem">{{goldredeem.unit}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
          <mat-cell *matCellDef="let goldredeem">{{goldredeem.amount}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="commission">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Commission</mat-header-cell>
          <mat-cell *matCellDef="let goldredeem">{{goldredeem.commission}}</mat-cell>
        </ng-container>

				<ng-container matColumnDef="created_at">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Created At</mat-header-cell>
					<mat-cell *matCellDef="let goldredeem">{{goldredeem.created_at}}</mat-cell>
				</ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            Actions
          </mat-header-cell>
          <mat-cell *matCellDef="let goldredeem">
            <button type="button" (click)="editGoldRedeem(goldredeem.id)" mat-icon-button color="primary" matTooltip="Detail Information">
              <mat-icon>edit</mat-icon>
            </button>&nbsp;
          </mat-cell>
        </ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
    
	</kt-portlet-body>

</kt-portlet>
