import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

const BASE_URL = environment.baseUrl;
const client_id = environment.client_id;
const client_secret = environment.client_secret;
const grant_type = environment.grant_type;
const scope = environment.scope;

@Injectable()
export class AuthService {
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}
    // Authentication/Authorization
    login(email: string, password: string): Observable<User> {
      return this.http.post<User>(BASE_URL+'partner/getLogin', {
        client_id,
        client_secret,
        grant_type,
        scope,
        email,
        password
      });
    }

    getUserByToken(): Observable<User> {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      return this.http.get<User>(BASE_URL+'partner/getProfile', { headers: httpHeaders });
    }

    register(user: User): Observable<any> {
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
      return this.http.post<User>(BASE_URL, user, { headers: httpHeaders })
        .pipe(
          map((res: User) => {
            return res;
          }),
          catchError(err => {
            return null;
          })
        );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
      return this.http.get(BASE_URL + '/forgot?=' + email)
      	.pipe(catchError(this.handleError('forgot-password', []))
  	  );
    }


    getAllUsers(): Observable<User[]> {
		  return this.http.get<User[]>(BASE_URL);
    }

    getUserById(userId: number): Observable<User> {
		  return this.http.get<User>(BASE_URL + `/${userId}`);
    }

    // DELETE => delete the user from the server
    deleteUser(userId: number) {
    	const url = `${BASE_URL}/${userId}`;
    	return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
	    return this.http.put(BASE_URL, _user, { headers: httpHeaders });
    }

    // CREATE =>  POST: add a new user to the server
  	createUser(user: User): Observable<User> {
    	const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
		  return this.http.post<User>(BASE_URL, user, { headers: httpHeaders});
  	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
      const url = BASE_URL + 'partner/getUsers';

      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params:  httpParams
      });
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      return this.http.get<Permission[]>(BASE_URL + 'partner/getPermissions', { headers: httpHeaders });
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
      return this.http.get<Permission[]>(BASE_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      return this.http.get<Role[]>(BASE_URL + 'partner/getRoles', { headers: httpHeaders });
    }

    getRoleById(roleId: number): Observable<Role> {
      return this.http.get<Role>(BASE_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	  createRole(role: Role): Observable<Role> {
		  // Note: Add headers if needed (tokens/bearer)
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
		  return this.http.post<Role>(BASE_URL, role, { headers: httpHeaders});
	  }

    // UPDATE => PUT: update the role on the server
	  updateRole(role: Role): Observable<any> {
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
		  return this.http.put(BASE_URL, role, { headers: httpHeaders });
    }

	  // DELETE => delete the role from the server
	  deleteRole(roleId: number): Observable<Role> {
		  const url = `${BASE_URL}/${roleId}`;
		  return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
      return this.http.get<boolean>(BASE_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
      const url = BASE_URL + 'partner/getRoles';

      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params:  httpParams
      });
    }

    // Download
    downloadUsers(beginDate: string, endDate: string) {
      const httpHeaders = this.httpUtils.getHTTPHeaders();
      const url = BASE_URL + 'partner/export/users'+'?beginDate=' + beginDate + '&endDate=' + endDate;    
      return this.http
        .get<any[]>(url,{headers:httpHeaders})
        .pipe(map(data => data['data']));
    }

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
      return (error: any): Observable<any> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // Let the app keep running by returning an empty result.
        return of(result);
      };
    }
}
