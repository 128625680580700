import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
    id: number;
    fullname: string;
    email: string;
    photo: string;
    phone: string;
    gold_balance: boolean;
    roles: number[];
    username: string;
    password: string;
    access_token: string;

    clear(): void {
        this.id = undefined;
        this.fullname = '';
        this.email = '';
        this.photo = '';
        this.phone = '';
        this.gold_balance = false;
        this.roles = [];
        this.username = '';
        this.password = '';
        this.access_token = 'access-token-' + Math.random();
    }
}
