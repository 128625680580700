// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { KonussState } from '../_reducers/konus.reducers';
import { KonusModel } from '../_models/konus.model';

export const selectKonussState = createFeatureSelector<KonussState>('konuss');

export const selectKonusById = (konusId: number) => createSelector(
    selectKonussState,
    konussState => konussState.entities[konusId]
);

export const selectKonussPageLoading = createSelector(
    selectKonussState,
    konussState => konussState.listLoading
);

export const selectKonussActionLoading = createSelector(
    selectKonussState,
    konussState => konussState.actionsloading
);

export const selectLastCreatedKonusId = createSelector(
    selectKonussState,
    konussState => konussState.lastCreatedKonusId
);

export const selectKonussShowInitWaitingMessage = createSelector(
    selectKonussState,
    konussState => konussState.showInitWaitingMessage
);

export const selectKonussInStore = createSelector(
    selectKonussState,
    konussState => {
        const items: KonusModel[] = [];
        each(konussState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: KonusModel[] = httpExtension.sortArray(items, konussState.lastQuery.sortField, konussState.lastQuery.sortOrder);
        return new QueryResultsModel(result, konussState.totalCount, '');
    }
);
