export class PageConfig {
	public defaults: any = {
		dashboard: {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic transactions'
			},
		},
    transaction: {
      'gold-buy': {
        page: {title: 'Gold Buy', desc: 'List of gold buy transaction'}
	  },
	  konus: {
        page: {title: 'Konus', desc: 'List of Koin Nusantara transaction'}
      },
      'gold-sell': {
        page: {title: 'Gold Sell', desc: 'List of gold sell transaction'}
      },
      'gold-redeem': {
        page: {title: 'Gold Redeem', desc: 'List of gold redeem transaction'}
      },
    },
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
