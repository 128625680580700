// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
// Services and Models
import { GoldBuyModel, GoldBuyUpdated, GoldBuyOnServerCreated, selectLastCreatedGoldBuyId, selectGoldBuysPageLoading, selectGoldBuysActionLoading } from '../../../../../core/transaction';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-goldbuys-edit-dialog',
	templateUrl: './gold-buy-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class GoldBuyEditComponent implements OnInit, OnDestroy {
	// Public properties
	goldbuy: GoldBuyModel;
	goldbuyForm: FormGroup;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<GoldBuyEditComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<GoldBuyEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.store.pipe(select(selectGoldBuysActionLoading)).subscribe(res => this.viewLoading = res);
		this.goldbuy = this.data.goldbuy;
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		this.goldbuyForm = this.fb.group({
			// firstName: [this.goldbuy.firstName, Validators.required],
			// lastName: [this.goldbuy.lastName, Validators.required],
			// email: [ this.goldbuy.email, Validators.compose([Validators.required, Validators.email]) ],
			// dob: [this.typesUtilsService.getDateFromString(this.goldbuy.dateOfBbirth), Validators.compose([Validators.nullValidator])],
			// userName: [this.goldbuy.userName, Validators.compose([Validators.required])],
			// gender: [this.goldbuy.gender, Validators.compose([Validators.required])],
			// ipAddress: [this.goldbuy.ipAddress, Validators.compose([Validators.required])],
			// type: [this.goldbuy.type.toString(), Validators.compose([Validators.required])]
		});
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return 'Detail Information';
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.goldbuyForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared goldbuy
	 */
	prepareGoldBuy(): GoldBuyModel {
		const controls = this.goldbuyForm.controls;
		const _goldbuy = new GoldBuyModel();
		_goldbuy.id = this.goldbuy.id;
		const _date = controls['dob'].value;
		// if (_date) {
		// 	_goldbuy.dateOfBbirth = this.typesUtilsService.dateFormat(_date);
		// } else {
		// 	_goldbuy.dateOfBbirth = '';
		// }
		// _goldbuy.firstName = controls['firstName'].value;
		// _goldbuy.lastName = controls['lastName'].value;
		// _goldbuy.email = controls['email'].value;
		// _goldbuy.userName = controls['userName'].value;
		// _goldbuy.gender = controls['gender'].value;
		// _goldbuy.ipAddress = controls['ipAddress'].value;
		// _goldbuy.type = +controls['type'].value;
		// _goldbuy.status = this.goldbuy.status;
		return _goldbuy;
	}

	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.goldbuyForm.controls;
		/** check form */
		if (this.goldbuyForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedGoldBuy = this.prepareGoldBuy();
		if (editedGoldBuy.id > 0) {
			this.updateGoldBuy(editedGoldBuy);
		} else {
			this.createGoldBuy(editedGoldBuy);
		}
	}

	/**
	 * Update goldbuy
	 *
	 * @param _goldbuy: GoldBuyModel
	 */
	updateGoldBuy(_goldbuy: GoldBuyModel) {
		const updateGoldBuy: Update<GoldBuyModel> = {
			id: _goldbuy.id,
			changes: _goldbuy
		};
		this.store.dispatch(new GoldBuyUpdated({
			partialGoldBuy: updateGoldBuy,
			goldbuy: _goldbuy
		}));

		// Remove this line
		of(undefined).pipe(delay(1000)).subscribe(() => this.dialogRef.close({ _goldbuy, isEdit: true }));
		// Uncomment this line
		// this.dialogRef.close({ _goldbuy, isEdit: true }
	}

	/**
	 * Create goldbuy
	 *
	 * @param _goldbuy: GoldBuyModel
	 */
	createGoldBuy(_goldbuy: GoldBuyModel) {
		this.store.dispatch(new GoldBuyOnServerCreated({ goldbuy: _goldbuy }));
		this.componentSubscriptions = this.store.pipe(
			select(selectLastCreatedGoldBuyId),
			delay(1000), // Remove this line
		).subscribe(res => {
			if (!res) {
				return;
			}

			this.dialogRef.close({ _goldbuy, isEdit: false });
		});
	}

	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
