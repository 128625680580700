// INDONESIA
export const locale = {
  lang: 'id',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    TRANSACTION: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      GOLDBUY: {
        TITLE: 'Gold Buy',
        LIST: 'Gold Buy list',
        NEW: 'New Gold Buy',
        DELETE_GOLD_BUY_SIMPLE: {
          TITLE: 'Gold Buy Delete',
          DESCRIPTION: 'Are you sure to permanently delete this gold buy?',
          WAIT_DESCRIPTION: 'Gold buy is deleting...',
          MESSAGE: 'Gold buy has been deleted'
        },
        DELETE_GOLD_BUY_MULTY: {
          TITLE: 'Gold buy Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected gold buy?',
          WAIT_DESCRIPTION: 'Gold buy are deleting...',
          MESSAGE: 'Selected gold buy have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected gold buy',
          MESSAGE: 'Selected gold buy status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Gold buy has been updated',
          ADD_MESSAGE: 'Gold buy has been created'
        }
      },
      GOLDSELL: {
        TITLE: 'Gold Sell',
        LIST: 'Gold Sell list',
        NEW: 'New Gold Sell',
        DELETE_GOLD_SELL_SIMPLE: {
          TITLE: 'Gold Sell Delete',
          DESCRIPTION: 'Are you sure to permanently delete this gold sell?',
          WAIT_DESCRIPTION: 'Gold sell is deleting...',
          MESSAGE: 'Gold sell has been deleted'
        },
        DELETE_GOLD_SELL_MULTY: {
          TITLE: 'Gold sell Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected gold sell?',
          WAIT_DESCRIPTION: 'Gold sell are deleting...',
          MESSAGE: 'Selected gold sell have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected gold sell',
          MESSAGE: 'Selected gold sell status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Gold sell has been updated',
          ADD_MESSAGE: 'Gold sell has been created'
        }
      },
      GOLDREDEEM: {
        TITLE: 'Gold Redeem',
        LIST: 'Gold Redeem list',
        NEW: 'New Gold Redeem',
        DELETE_GOLD_REDEEM_SIMPLE: {
          TITLE: 'Gold Redeem Delete',
          DESCRIPTION: 'Are you sure to permanently delete this gold redeem?',
          WAIT_DESCRIPTION: 'Gold redeem is deleting...',
          MESSAGE: 'Gold redeem has been deleted'
        },
        DELETE_GOLD_REDEEM_MULTY: {
          TITLE: 'Gold redeem Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected gold redeem?',
          WAIT_DESCRIPTION: 'Gold redeem are deleting...',
          MESSAGE: 'Selected gold redeem have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected gold redeem',
          MESSAGE: 'Selected gold redeem status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Gold redeem has been updated',
          ADD_MESSAGE: 'Gold redeem has been created'
        }
      }
    }
  }
};
