import { BaseModel } from '../../_base/crud';

export class GoldSellModel  extends BaseModel {
  id: number;
  name: string;
  email: string;
  unit: string;
  amount: string;
  selling_rate: string;
  created_at: string;

  clear() {
    this.name = '';
    this.email = '';
    this.unit = '';
    this.amount = '';
    this.selling_rate = '';
    this.created_at = '';
  }
}
