// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
// CRUD
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel } from '../models/query-models/query-params.model';
import { HttpExtenstionsModel } from '../../crud/models/http-extentsions-model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class HttpUtilsService {
	/**
	 * Prepare query http params
	 * @param queryParams: QueryParamsModel
	 */
	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
		.set('page', queryParams.pageNumber.toString())
		.set('limit', queryParams.pageSize.toString())
		.set('beginDate', queryParams.beginDate)
		// .set('beginDate', queryParams.beginDate)
		.set('endDate', queryParams.endDate)
		// .set('endDate', queryParams.endDate)
		.set('order', queryParams.sortOrder)
			.set('sort', 'created_at')
		// .set('sort', queryParams.sortField)
		.set('status', '[0,1]')
		.set('type', '')
		.set('filter', queryParams.filter);
		return params;
	}

  serialize = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

	/**
	 * get standard content-type
	 */
	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders()
		.set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer '+localStorage.getItem(environment.authToken));
		return result;
	}

	baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
	}

	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
	}

	searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
	}
}
