// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { GoldBuyActions, GoldBuyActionTypes } from '../_actions/goldbuy.actions';
// Models
import { GoldBuyModel } from '../_models/goldbuy.model';
import { QueryParamsModel } from '../../_base/crud';

export interface GoldBuysState extends EntityState<GoldBuyModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGoldBuyId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GoldBuyModel> = createEntityAdapter<GoldBuyModel>();

export const initialGoldBuysState: GoldBuysState = adapter.getInitialState({
    goldbuyForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGoldBuyId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function goldbuysReducer(state = initialGoldBuysState, action: GoldBuyActions): GoldBuysState {
    switch  (action.type) {
        case GoldBuyActionTypes.GoldBuysPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGoldBuyId: undefined
            };
        }
        case GoldBuyActionTypes.GoldBuyActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case GoldBuyActionTypes.GoldBuyOnServerCreated: return {
            ...state
        };
        case GoldBuyActionTypes.GoldBuyCreated: return adapter.addOne(action.payload.goldbuy, {
            ...state, lastCreatedGoldBuyId: action.payload.goldbuy.id
        });
        case GoldBuyActionTypes.GoldBuyUpdated: return adapter.updateOne(action.payload.partialGoldBuy, state);
       //  case GoldBuyActionTypes.GoldBuysStatusUpdated: {
       //      const _partialGoldBuys: Update<GoldBuyModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.goldbuys.length; i++) {
       //          _partialGoldBuys.push({
				   //  id: action.payload.goldbuys[i].id,
				   //  changes: {
       //                  status: action.payload.status
       //              }
			    // });
       //      }
       //      return adapter.updateMany(_partialGoldBuys, state);
       //  }
        case GoldBuyActionTypes.OneGoldBuyDeleted: return adapter.removeOne(action.payload.id, state);
        case GoldBuyActionTypes.ManyGoldBuysDeleted: return adapter.removeMany(action.payload.ids, state);
        case GoldBuyActionTypes.GoldBuysPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case GoldBuyActionTypes.GoldBuysPageLoaded: {
            return adapter.addMany(action.payload.goldbuys, {
                ...initialGoldBuysState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getGoldBuyState = createFeatureSelector<GoldBuyModel>('goldbuys');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
