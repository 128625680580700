// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldRedeemModel } from '../_models/goldredeem.model';

export enum GoldRedeemActionTypes {
    GoldRedeemOnServerCreated = '[Edit GoldRedeem Dialog] GoldRedeem On Server Created',
    GoldRedeemCreated = '[Edit GoldRedeem Dialog] GoldRedeem Created',
    GoldRedeemUpdated = '[Edit GoldRedeem Dialog] GoldRedeem Updated',
    GoldRedeemsStatusUpdated = '[GoldRedeem List Page] GoldRedeems Status Updated',
    OneGoldRedeemDeleted = '[GoldRedeems List Page] One GoldRedeem Deleted',
    ManyGoldRedeemsDeleted = '[GoldRedeems List Page] Many GoldRedeem Deleted',
    GoldRedeemsPageRequested = '[GoldRedeems List Page] GoldRedeems Page Requested',
    GoldRedeemsPageLoaded = '[GoldRedeems API] GoldRedeems Page Loaded',
    GoldRedeemsPageCancelled = '[GoldRedeems API] GoldRedeems Page Cancelled',
    GoldRedeemsPageToggleLoading = '[GoldRedeems] GoldRedeems Page Toggle Loading',
    GoldRedeemActionToggleLoading = '[GoldRedeems] GoldRedeems Action Toggle Loading'
}

export class GoldRedeemOnServerCreated implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemOnServerCreated;
    constructor(public payload: { goldredeem: GoldRedeemModel }) { }
}

export class GoldRedeemCreated implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemCreated;
    constructor(public payload: { goldredeem: GoldRedeemModel }) { }
}

export class GoldRedeemUpdated implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemUpdated;
    constructor(public payload: {
        partialGoldRedeem: Update<GoldRedeemModel>, // For State update
        goldredeem: GoldRedeemModel // For Server update (through service)
    }) { }
}

export class GoldRedeemsStatusUpdated implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemsStatusUpdated;
    constructor(public payload: {
        goldredeems: GoldRedeemModel[],
        status: number
    }) { }
}

export class OneGoldRedeemDeleted implements Action {
    readonly type = GoldRedeemActionTypes.OneGoldRedeemDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldRedeemsDeleted implements Action {
    readonly type = GoldRedeemActionTypes.ManyGoldRedeemsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldRedeemsPageRequested implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldRedeemsPageLoaded implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemsPageLoaded;
    constructor(public payload: { goldredeems: GoldRedeemModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldRedeemsPageCancelled implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemsPageCancelled;
}

export class GoldRedeemsPageToggleLoading implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldRedeemActionToggleLoading implements Action {
    readonly type = GoldRedeemActionTypes.GoldRedeemActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldRedeemActions = GoldRedeemOnServerCreated
| GoldRedeemCreated
| GoldRedeemUpdated
| GoldRedeemsStatusUpdated
| OneGoldRedeemDeleted
| ManyGoldRedeemsDeleted
| GoldRedeemsPageRequested
| GoldRedeemsPageLoaded
| GoldRedeemsPageCancelled
| GoldRedeemsPageToggleLoading
| GoldRedeemActionToggleLoading;
