// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  GoldRedeemModel,
  GoldRedeemsDataSource,
  GoldRedeemsPageRequested,
  OneGoldRedeemDeleted,
  ManyGoldRedeemsDeleted,
  GoldRedeemsStatusUpdated,
  GoldRedeemService
} from '../../../../../core/transaction';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { GoldRedeemEditComponent } from '../gold-redeem-edit/gold-redeem-edit.dialog.component';
@Component({
	selector: 'kt-goldredeems-list',
	templateUrl: './gold-redeem-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GoldRedeemListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: GoldRedeemsDataSource;
	displayedColumns = ['name', 'email', 'unit', 'amount', 'created_at'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  dateRange: any = {};
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	// Selection
	selection = new SelectionModel<GoldRedeemModel>(true, []);
	goldredeemsResult: GoldRedeemModel[] = [];
  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Download',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'cloud_download'
    }
  }
	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe,
    private goldRedeemService: GoldRedeemService
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

    // var date = new Date();
    // let beginDate = date.setDate(date.getDate() - 7);
    // this.dateRange = {begin: new Date(beginDate), end: new Date()};

		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadGoldRedeemsList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadGoldRedeemsList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new GoldRedeemsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.goldredeemsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		// First load
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
			this.loadGoldRedeemsList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load GoldRedeems List from service through data-source
	 */
	loadGoldRedeemsList() {
		this.selection.clear();
		const searchText: string = this.searchInput.nativeElement.value;
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const queryParams = new QueryParamsModel(
			searchText,
			begin,
			end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new GoldRedeemsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

  onDateChange(event: any) {
    this.paginator.pageIndex = 0;
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadGoldRedeemsList();
  }

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};
    // console.log(this.dateRange.begin)
    // filter.dateRange = this.dateRange;
		// if (!searchText) {
  // 		return searchText;
		//   // filter.text = searchText;
		// }
  //   return null
	}

	/** ACTIONS */
	/**
	 * Delete goldredeem
	 *
	 * @param _item: GoldRedeemModel
	 */
	deleteGoldRedeem(_item: GoldRedeemModel) {
		const _title: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_SIMPLE.TITLE');
		const _description: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_SIMPLE.DESCRIPTION');
		const _waitDesciption: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_SIMPLE.WAIT_DESCRIPTION');
		const _deleteMessage = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_SIMPLE.MESSAGE');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.store.dispatch(new OneGoldRedeemDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	/**
	 * Delete selected goldredeems
	 */
	deleteGoldRedeems() {
		const _title: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_MULTY.TITLE');
		const _description: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_MULTY.DESCRIPTION');
		const _waitDesciption: string = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_MULTY.WAIT_DESCRIPTION');
		const _deleteMessage = this.translate.instant('TRANSACTION.GOLDREDEEM.DELETE_GOLD_REDEEM_MULTY.MESSAGE');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			const idsForDeletion: number[] = [];
			for (let i = 0; i < this.selection.selected.length; i++) {
				idsForDeletion.push(this.selection.selected[i].id);
			}
			this.store.dispatch(new ManyGoldRedeemsDeleted({ ids: idsForDeletion }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
			this.selection.clear();
		});
	}

	/**
	 * Fetch selected goldredeems
	 */
	fetchGoldRedeems() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				// text: `${elem.lastName}, ${elem.firstName}`,
				// id: elem.id.toString(),
				// status: elem.status
			});
		});
		this.layoutUtilsService.fetchElements(messages);
	}

	/**
	 * Show UpdateStatuDialog for selected goldredeems
	 */
	updateStatusForGoldRedeems() {
		const _title = this.translate.instant('TRANSACTION.GOLDREDEEM.UPDATE_STATUS.TITLE');
		const _updateMessage = this.translate.instant('TRANSACTION.GOLDREDEEM.UPDATE_STATUS.MESSAGE');
		const _statuses = [{ value: 0, text: 'Suspended' }, { value: 1, text: 'Active' }, { value: 2, text: 'Pending' }];
		const _messages = [];

		this.selection.selected.forEach(elem => {
			_messages.push({
				// text: `${elem.lastName}, ${elem.firstName}`,
				// id: elem.id.toString(),
				// status: elem.status,
				// statusTitle: this.getItemStatusString(elem.status),
				// statusCssClass: this.getItemCssClassByStatus(elem.status)
			});
		});

		const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				this.selection.clear();
				return;
			}

			this.store.dispatch(new GoldRedeemsStatusUpdated({
				status: +res,
				goldredeems: this.selection.selected
			}));

			this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update, 10000, true, true);
			this.selection.clear();
		});
	}

	/**
	 * Show add goldredeem dialog
	 */
	addGoldRedeem() {
		const newGoldRedeem = new GoldRedeemModel();
		newGoldRedeem.clear(); // Set all defaults fields
		this.editGoldRedeem(newGoldRedeem);
	}

	/**
	 * Show Edit goldredeem dialog and save after success close result
	 * @param goldredeem: GoldRedeemModel
	 */
	editGoldRedeem(goldredeem: GoldRedeemModel) {
		let saveMessageTranslateParam = 'TRANSACTION.GOLDREDEEM.EDIT.';
		saveMessageTranslateParam += goldredeem.id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
		const _saveMessage = this.translate.instant(saveMessageTranslateParam);
		const _messageType = goldredeem.id > 0 ? MessageType.Update : MessageType.Create;
		const dialogRef = this.dialog.open(GoldRedeemEditComponent, { data: { goldredeem } });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
			this.loadGoldRedeemsList();
		});
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.goldredeemsResult.length;
		return numSelected === numRows;
	}

	/**
	 * Toggle all selections
	 */
	masterToggle() {
		if (this.selection.selected.length === this.goldredeemsResult.length) {
			this.selection.clear();
		} else {
			this.goldredeemsResult.forEach(row => this.selection.select(row));
		}
	}

  download(){
	this.spinnerButtonOptions.active = true;
	const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
	const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    this.goldRedeemService.downloadGoldRedeem(
      begin,
      end)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }
}
