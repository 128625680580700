<kt-portlet>
	<!-- PORTLET LOADING | Binded to TABLE Datasource -->
	<!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
	<kt-portlet-header [title]="'Users List'" [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
      <mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button-mt-4"></mat-spinner-button>
			<!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
			<!-- mat-raised-button | Rectangular contained button w/ elevation  -->
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<!-- start::Body (attribute: ktPortletBody) -->
	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="kt-form kt-margin-b-30">
			<!-- start::FILTERS -->
			<div class="kt-form__filtration">
				<div class="row align-items-center">
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Search goldbuy" #searchInput placeholder="Search">
              <mat-hint align="start">
                <strong>Search</strong> in all fields
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
			<mat-form-field class="mat-form-field-fluid">
				<input matInput
					placeholder="Choose a date"
					[satDatepicker]="picker"
					[(value)]="dateRange"
					(dateChange)="onDateChange($event)">
				<sat-datepicker
					#picker
					[rangeMode]="true"
					orderPeriodLabel="month"
					[closeAfterSelection]="true">
				</sat-datepicker>
				<sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
			</mat-form-field>
			</div>
				</div>
			</div>
			<!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS -->
			<div class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}"><!-- We show 'Group Actions' div if smth are selected -->
				<div class="col-xl-12">
					<div class="kt-form__group kt-form__group--inline">
						<div class="kt-form__label kt-form__label-no-wrap">
							<label class="kt--font-bold kt-font-danger-">
								<span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span> {{ selection.selected.length }}
							</label>
							<!-- selectedCountsTitle => function from codeBehind (users-list.component.ts file) -->
							<!-- selectedCountsTitle => just returns title of selected items count -->
							<!-- for example: Selected records count: 4 -->
						</div>
						<div class="kt-form__control kt-form__group--inline">
							<button (click)="fetchUsers()" mat-raised-button matTooltip="Fetch selected users"  class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="asc"
				matSortDisableClear>
				<!-- Checkbox Column -->

				<!-- Table with selection -->
				<ng-container matColumnDef="select">
					<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
						<mat-checkbox (change)="$event ? masterToggle() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="mat-column-checkbox">
						<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="fullname">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Fullname</mat-header-cell>
					<mat-cell *matCellDef="let user">{{user.fullname}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="email">
					<mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Email</mat-header-cell>
					<mat-cell *matCellDef="let user" class="email-cell">
						<a href="user-management/users#" class="kt-link">{{user.email}}</a>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="phone">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
					<mat-cell *matCellDef="let user">{{user.phone}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="gold_balance">
					<mat-header-cell *matHeaderCellDef>Gold Balance</mat-header-cell>
					<mat-cell *matCellDef="let user">{{user.gold_balance}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="bonus_gold_balance">
					<mat-header-cell *matHeaderCellDef>Gold Bonus Balance</mat-header-cell>
					<mat-cell *matCellDef="let user">{{user.bonus_gold_balance}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let user">
						<button (click)="editUser(user.id)" mat-icon-button color="primary" matTooltip="Edit user">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" matTooltip="Delete user" type="button" (click)="deleteUser(user)">
							<mat-icon>delete</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div><!-- Message for empty data  -->
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table__bottom">
			<!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<!-- MATERIAL PAGINATOR | Binded to dasources -->
			<!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>
