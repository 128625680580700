// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { GoldRedeemModel } from '../_models/goldredeem.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class GoldRedeemService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new goldredeem to the server
  createGoldRedeem(goldredeem: GoldRedeemModel): Observable<GoldRedeemModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<GoldRedeemModel>(BASE_URL, goldredeem, { headers: httpHeaders});
  }

  // READ
  getAllGoldRedeems(): Observable<GoldRedeemModel[]> {
    return this.http.get<GoldRedeemModel[]>(BASE_URL);
  }

  getGoldRedeemById(goldredeemId: number): Observable<GoldRedeemModel> {
    return this.http.get<GoldRedeemModel>(BASE_URL + `/${goldredeemId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findGoldRedeems(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'partner/goldRedeem';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the goldredeem on the server
  updateGoldRedeem(goldredeem: GoldRedeemModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, goldredeem, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForGoldRedeem(goldredeems: GoldRedeemModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      goldredeemsForUpdate: goldredeems,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the goldredeem from the server
  deleteGoldRedeem(goldredeemId: number): Observable<GoldRedeemModel> {
    const url = `${BASE_URL}/${goldredeemId}`;
    return this.http.delete<GoldRedeemModel>(url);
  }

  deleteGoldRedeems(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteGoldRedeems';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { goldredeemIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }

   // Download
  downloadGoldRedeem(beginDate: string, endDate: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'partner/export/goldredeem'+'?beginDate=' + beginDate + '&endDate=' + endDate;    
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }
}
