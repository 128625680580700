export class QueryResultsModel {
	// fields
	data: any[];
	totalCount: number;
	errorMessage: string;

	constructor(_data: any[] = [], _totalCount: number = 0, _errorMessage: string = '') {
		this.data = _data;
		this.totalCount = _totalCount;
	}
}
