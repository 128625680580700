// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// NGRX
import { select, Store } from '@ngrx/store';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  /**
   * Component constructor
   *
   */
  constructor(private router: Router) {
  }

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
					}
				},
				error => {
          if (error.status == 401) {
            localStorage.clear();
            this.router.navigateByUrl('auth');
          }
				}
			)
		);
	}
}
