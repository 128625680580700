// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldSellModel } from '../_models/goldsell.model';

export enum GoldSellActionTypes {
    GoldSellOnServerCreated = '[Edit GoldSell Dialog] GoldSell On Server Created',
    GoldSellCreated = '[Edit GoldSell Dialog] GoldSell Created',
    GoldSellUpdated = '[Edit GoldSell Dialog] GoldSell Updated',
    GoldSellsStatusUpdated = '[GoldSell List Page] GoldSells Status Updated',
    OneGoldSellDeleted = '[GoldSells List Page] One GoldSell Deleted',
    ManyGoldSellsDeleted = '[GoldSells List Page] Many GoldSell Deleted',
    GoldSellsPageRequested = '[GoldSells List Page] GoldSells Page Requested',
    GoldSellsPageLoaded = '[GoldSells API] GoldSells Page Loaded',
    GoldSellsPageCancelled = '[GoldSells API] GoldSells Page Cancelled',
    GoldSellsPageToggleLoading = '[GoldSells] GoldSells Page Toggle Loading',
    GoldSellActionToggleLoading = '[GoldSells] GoldSells Action Toggle Loading'
}

export class GoldSellOnServerCreated implements Action {
    readonly type = GoldSellActionTypes.GoldSellOnServerCreated;
    constructor(public payload: { goldsell: GoldSellModel }) { }
}

export class GoldSellCreated implements Action {
    readonly type = GoldSellActionTypes.GoldSellCreated;
    constructor(public payload: { goldsell: GoldSellModel }) { }
}

export class GoldSellUpdated implements Action {
    readonly type = GoldSellActionTypes.GoldSellUpdated;
    constructor(public payload: {
        partialGoldSell: Update<GoldSellModel>, // For State update
        goldsell: GoldSellModel // For Server update (through service)
    }) { }
}

export class GoldSellsStatusUpdated implements Action {
    readonly type = GoldSellActionTypes.GoldSellsStatusUpdated;
    constructor(public payload: {
        goldsells: GoldSellModel[],
        status: number
    }) { }
}

export class OneGoldSellDeleted implements Action {
    readonly type = GoldSellActionTypes.OneGoldSellDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldSellsDeleted implements Action {
    readonly type = GoldSellActionTypes.ManyGoldSellsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldSellsPageRequested implements Action {
    readonly type = GoldSellActionTypes.GoldSellsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldSellsPageLoaded implements Action {
    readonly type = GoldSellActionTypes.GoldSellsPageLoaded;
    constructor(public payload: { goldsells: GoldSellModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldSellsPageCancelled implements Action {
    readonly type = GoldSellActionTypes.GoldSellsPageCancelled;
}

export class GoldSellsPageToggleLoading implements Action {
    readonly type = GoldSellActionTypes.GoldSellsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldSellActionToggleLoading implements Action {
    readonly type = GoldSellActionTypes.GoldSellActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldSellActions = GoldSellOnServerCreated
| GoldSellCreated
| GoldSellUpdated
| GoldSellsStatusUpdated
| OneGoldSellDeleted
| ManyGoldSellsDeleted
| GoldSellsPageRequested
| GoldSellsPageLoaded
| GoldSellsPageCancelled
| GoldSellsPageToggleLoading
| GoldSellActionToggleLoading;
