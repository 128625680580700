import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import { selectGoldSellsInStore, selectGoldSellsPageLoading, selectGoldSellsShowInitWaitingMessage } from '../_selectors/goldsell.selectors';

export class GoldSellsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(
      select(selectGoldSellsPageLoading),
    );

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectGoldSellsShowInitWaitingMessage)
    );

    this.store.pipe(
      select(selectGoldSellsInStore),
    ).subscribe((response: QueryResultsModel) => {
      this.paginatorTotalSubject.next(response.totalCount);
      this.entitySubject.next(response.data);
    });
  }
}
