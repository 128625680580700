// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../partials/partials.module';
// Auth
import { ModuleGuard } from '../../../core/auth';
// Core => Services
import {
	goldbuysReducer,
	GoldBuyEffects,
	GoldBuyService,
	konussReducer,
	KonusEffects,
	KonusService,
  goldsellsReducer,
  GoldSellEffects,
  GoldSellService,
  goldredeemsReducer,
  GoldRedeemEffects,
  GoldRedeemService,
} from '../../../core/transaction';
// Core => Utils
import { HttpUtilsService,
	TypesUtilsService,
	InterceptService,
	LayoutUtilsService
} from '../../../core/_base/crud';
// Shared
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import { TransactionComponent } from './transaction.component';
// Gold Buy
import { GoldBuyListComponent } from './gold-buy/gold-buy-list/gold-buy-list.component';
import { GoldBuyEditComponent } from './gold-buy/gold-buy-edit/gold-buy-edit.dialog.component';

import { KonusListComponent } from './konus/konus-list/konus-list.component';
import { KonusEditComponent } from './konus/konus-edit/konus-edit.dialog.component';

// Gold Sell
import { GoldSellListComponent } from './gold-sell/gold-sell-list/gold-sell-list.component';
import { GoldSellEditComponent } from './gold-sell/gold-sell-edit/gold-sell-edit.dialog.component';

// Gold Redeem
import { GoldRedeemListComponent } from './gold-redeem/gold-redeem-list/gold-redeem-list.component';
import { GoldRedeemEditComponent } from './gold-redeem/gold-redeem-edit/gold-redeem-edit.dialog.component';
// Button Spinner
import { MatProgressButtonsModule } from 'mat-progress-buttons';

// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
  MatFormFieldModule
} from '@angular/material';
// Date Range
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../core/_base/layout';

import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: TransactionComponent,
		children: [
			{
				path: '',
				redirectTo: 'transaction',
				pathMatch: 'full'
			},
			{
				path: 'gold-buy',
				component: GoldBuyListComponent
			},
			{
				path: 'konus',
				component: KonusListComponent
			},
			{
				path: 'gold-sell',
				component: GoldSellListComponent
			},
			{ path: 'gold-redeem',
				component: GoldRedeemListComponent
			},
		]
	}
];

@NgModule({
	imports: [
    	MatProgressButtonsModule,
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		SatDatepickerModule,
		SatNativeDateModule,
		StoreModule.forFeature('goldbuys', goldbuysReducer),
		EffectsModule.forFeature([GoldBuyEffects]),
		StoreModule.forFeature('konuss', konussReducer),
		EffectsModule.forFeature([KonusEffects]),
		StoreModule.forFeature('goldsells', goldsellsReducer),
		EffectsModule.forFeature([GoldSellEffects]),
		StoreModule.forFeature('goldredeems', goldredeemsReducer),
		EffectsModule.forFeature([GoldRedeemEffects]),
	],
	providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DatePipe,
		ModuleGuard,
		InterceptService,
      	{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
        	multi: true
      	},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		GoldBuyService,
		KonusService,
    GoldSellService,
    GoldRedeemService,
		TypesUtilsService,
		LayoutUtilsService,
	],
	entryComponents: [
		ActionNotificationComponent,
		GoldBuyEditComponent,
		KonusEditComponent,
    GoldSellEditComponent,
    GoldRedeemEditComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
	declarations: [
		TransactionComponent,
		GoldBuyListComponent,
		GoldBuyEditComponent,
		KonusListComponent,
		KonusEditComponent,
    GoldSellListComponent,
    GoldSellEditComponent,
    GoldRedeemListComponent,
    GoldRedeemEditComponent,
	]
})
export class TransactionModule { }
