// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
// Services and Models
import { GoldSellModel, GoldSellUpdated, GoldSellOnServerCreated, selectLastCreatedGoldSellId, selectGoldSellsPageLoading, selectGoldSellsActionLoading } from '../../../../../core/transaction';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-goldsells-edit-dialog',
	templateUrl: './gold-sell-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class GoldSellEditComponent implements OnInit, OnDestroy {
	// Public properties
	goldsell: GoldSellModel;
	goldsellForm: FormGroup;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<GoldSellEditComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<GoldSellEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.store.pipe(select(selectGoldSellsActionLoading)).subscribe(res => this.viewLoading = res);
		this.goldsell = this.data.goldsell;
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		this.goldsellForm = this.fb.group({
			// firstName: [this.goldsell.firstName, Validators.required],
			// lastName: [this.goldsell.lastName, Validators.required],
			// email: [ this.goldsell.email, Validators.compose([Validators.required, Validators.email]) ],
			// dob: [this.typesUtilsService.getDateFromString(this.goldsell.dateOfBbirth), Validators.compose([Validators.nullValidator])],
			// userName: [this.goldsell.userName, Validators.compose([Validators.required])],
			// gender: [this.goldsell.gender, Validators.compose([Validators.required])],
			// ipAddress: [this.goldsell.ipAddress, Validators.compose([Validators.required])],
			// type: [this.goldsell.type.toString(), Validators.compose([Validators.required])]
		});
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return 'Detail Information';
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.goldsellForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared goldsell
	 */
	prepareGoldSell(): GoldSellModel {
		const controls = this.goldsellForm.controls;
		const _goldsell = new GoldSellModel();
		_goldsell.id = this.goldsell.id;
		const _date = controls['dob'].value;
		// if (_date) {
		// 	_goldsell.dateOfBbirth = this.typesUtilsService.dateFormat(_date);
		// } else {
		// 	_goldsell.dateOfBbirth = '';
		// }
		// _goldsell.firstName = controls['firstName'].value;
		// _goldsell.lastName = controls['lastName'].value;
		// _goldsell.email = controls['email'].value;
		// _goldsell.userName = controls['userName'].value;
		// _goldsell.gender = controls['gender'].value;
		// _goldsell.ipAddress = controls['ipAddress'].value;
		// _goldsell.type = +controls['type'].value;
		// _goldsell.status = this.goldsell.status;
		return _goldsell;
	}

	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.goldsellForm.controls;
		/** check form */
		if (this.goldsellForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedGoldSell = this.prepareGoldSell();
		if (editedGoldSell.id > 0) {
			this.updateGoldSell(editedGoldSell);
		} else {
			this.createGoldSell(editedGoldSell);
		}
	}

	/**
	 * Update goldsell
	 *
	 * @param _goldsell: GoldSellModel
	 */
	updateGoldSell(_goldsell: GoldSellModel) {
		const updateGoldSell: Update<GoldSellModel> = {
			id: _goldsell.id,
			changes: _goldsell
		};
		this.store.dispatch(new GoldSellUpdated({
			partialGoldSell: updateGoldSell,
			goldsell: _goldsell
		}));

		// Remove this line
		of(undefined).pipe(delay(1000)).subscribe(() => this.dialogRef.close({ _goldsell, isEdit: true }));
		// Uncomment this line
		// this.dialogRef.close({ _goldsell, isEdit: true }
	}

	/**
	 * Create goldsell
	 *
	 * @param _goldsell: GoldSellModel
	 */
	createGoldSell(_goldsell: GoldSellModel) {
		this.store.dispatch(new GoldSellOnServerCreated({ goldsell: _goldsell }));
		this.componentSubscriptions = this.store.pipe(
			select(selectLastCreatedGoldSellId),
			delay(1000), // Remove this line
		).subscribe(res => {
			if (!res) {
				return;
			}

			this.dialogRef.close({ _goldsell, isEdit: false });
		});
	}

	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
