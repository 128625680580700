<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress' : viewLoading, 'kt-portlet--body-progress-overlay' : loadingAfterSubmit }">
    <div class="kt-portlet__head kt-portlet__head__custom" *ngIf="role">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
        </div>
    </div>
    <div *ngIf="role">
        <div class="kt-form">
            <div class="kt-portlet__body">

                <div class="kt-portlet__body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                    Oh snap! Change a few things up and try submitting again.
                </kt-alert>

                <div class="form-group kt-form__group row d-block">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput 
                                [disabled]="role.isCoreRole"
                                placeholder="Enter Title"  
                                [(ngModel)]="role.title" />
                            <mat-error>Title is
                                <strong>required</strong>
                            </mat-error>
                            <mat-hint align="start">Please enter
                                <strong>Title</strong>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="kt-separator kt-separator--dashed"></div>
                <h6 class="kt-section__heading">
                    Permissions:
                </h6>
                <div class="form-group kt-form__group row">
                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                        <div class="kt-timeline-3 mb-5">
                            <div class="kt-timeline-3__items kt-timeline-3__items--rolePermissions">
                                <div *ngFor="let _rootRole of rolePermissions" class="kt-timeline-3__inner">
                                    
                                    <!-- {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}} -->
                                    <!-- <button mat-icon-button>
                                        <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                                    </button> -->
                                    <div class="kt-timeline-3__item kt-border-bottom-grey kt-py-15 kt-bg-grey">
                                        <span class="kt-timeline-3__item-time">
                                            <mat-checkbox [(ngModel)]="_rootRole.isSelected"
                                                (change)="isSelectedChanged($event, _rootRole)"
                                                [disabled]="role.isCoreRole">{{ _rootRole.title }}</mat-checkbox> 
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center kt-border-bottom-grey kt-py-15 kt-bg-grey">
                                        <div class="kt-timeline-3__item kt-timeline-3__item-child"
                                            *ngFor="let _childRole of _rootRole._children">
                                            <span class="kt-timeline-3__item-time">
                                                <mat-checkbox [(ngModel)]="_childRole.isSelected"
                                                    (change)="isSelectedChanged($event, _childRole)"
                                                    [disabled]="role.isCoreRole">{{ _childRole.title }}</mat-checkbox>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
                <div class="kt-form__actions kt-form__actions--solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Cancel changes">
                                Cancel
                            </button>&nbsp;
                            <button type="button" [disabled]="!isTitleValid() || role.isCoreRole" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="viewLoading" matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
