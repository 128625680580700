import { BaseModel } from '../../_base/crud';

export class KonusModel  extends BaseModel {
  id: number;
  name: string;
  email: string;
  item_total: string;
  commission: string;
  created_at: string;

  clear() {
    this.name = '';
    this.email = '';
    this.item_total = '';
    this.commission = '';
    this.created_at = '';
  }
}
