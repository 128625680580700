// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldRedeemsState } from '../_reducers/goldredeem.reducers';
import { GoldRedeemModel } from '../_models/goldredeem.model';

export const selectGoldRedeemsState = createFeatureSelector<GoldRedeemsState>('goldredeems');

export const selectGoldRedeemById = (goldredeemId: number) => createSelector(
    selectGoldRedeemsState,
    goldredeemsState => goldredeemsState.entities[goldredeemId]
);

export const selectGoldRedeemsPageLoading = createSelector(
    selectGoldRedeemsState,
    goldredeemsState => goldredeemsState.listLoading
);

export const selectGoldRedeemsActionLoading = createSelector(
    selectGoldRedeemsState,
    goldredeemsState => goldredeemsState.actionsloading
);

export const selectLastCreatedGoldRedeemId = createSelector(
    selectGoldRedeemsState,
    goldredeemsState => goldredeemsState.lastCreatedGoldRedeemId
);

export const selectGoldRedeemsShowInitWaitingMessage = createSelector(
    selectGoldRedeemsState,
    goldredeemsState => goldredeemsState.showInitWaitingMessage
);

export const selectGoldRedeemsInStore = createSelector(
    selectGoldRedeemsState,
    goldredeemsState => {
        const items: GoldRedeemModel[] = [];
        each(goldredeemsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldRedeemModel[] = httpExtension.sortArray(items, goldredeemsState.lastQuery.sortField, goldredeemsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, goldredeemsState.totalCount, '');
    }
);
