// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldSellsState } from '../_reducers/goldsell.reducers';
import { GoldSellModel } from '../_models/goldsell.model';

export const selectGoldSellsState = createFeatureSelector<GoldSellsState>('goldsells');

export const selectGoldSellById = (goldsellId: number) => createSelector(
    selectGoldSellsState,
    goldsellsState => goldsellsState.entities[goldsellId]
);

export const selectGoldSellsPageLoading = createSelector(
    selectGoldSellsState,
    goldsellsState => goldsellsState.listLoading
);

export const selectGoldSellsActionLoading = createSelector(
    selectGoldSellsState,
    goldsellsState => goldsellsState.actionsloading
);

export const selectLastCreatedGoldSellId = createSelector(
    selectGoldSellsState,
    goldsellsState => goldsellsState.lastCreatedGoldSellId
);

export const selectGoldSellsShowInitWaitingMessage = createSelector(
    selectGoldSellsState,
    goldsellsState => goldsellsState.showInitWaitingMessage
);

export const selectGoldSellsInStore = createSelector(
    selectGoldSellsState,
    goldsellsState => {
        const items: GoldSellModel[] = [];
        each(goldsellsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldSellModel[] = httpExtension.sortArray(items, goldsellsState.lastQuery.sortField, goldsellsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, goldsellsState.totalCount, '');
    }
);
