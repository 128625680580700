// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { KonusModel } from '../_models/konus.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class KonusService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Konus to the server
	createKonus(konus: KonusModel): Observable<KonusModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<KonusModel>(BASE_URL, konus, { headers: httpHeaders});
	}

	// READ
	getAllKonuss(): Observable<KonusModel[]> {
		return this.http.get<KonusModel[]>(BASE_URL);
	}

	getKonusById(konusId: number): Observable<KonusModel> {
		return this.http.get<KonusModel>(BASE_URL + `/${konusId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findKonuss(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'partner/konus';

    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the goldbuy on the server
	updateKonus(konus: KonusModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, konus, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForKonus(konuss: KonusModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			konussForUpdate: konuss,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the goldbuy from the server
	deleteKonus(konusId: number): Observable<KonusModel> {
		const url = `${BASE_URL}/${konusId}`;
		return this.http.delete<KonusModel>(url);
	}

	deleteKonuss(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteKonuss';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { konusIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}

  // Download
  downloadKonus(beginDate: string, endDate: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'partner/export/konus'+'?beginDate=' + beginDate + '&endDate=' + endDate;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }
}
