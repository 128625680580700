export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboard.',
					root: true,
					alignment: 'left',
					page: '/dashboard',
				},
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					root: true,
					bullet: 'dot',
				},
        {section: 'Transaction'},
        // {
        //   title: 'Gold Buy',
        //   icon: 'flaticon2-expand',
        //   page: '/transaction/gold-buy',
        //   root: true,
        //   permission: 'accessToGoldBuy',
        // },
        {
          title: 'Gold Buy',
          icon: 'flaticon2-expand',
          page: '/transaction/gold-buy',
          root: true,
          // permission: 'accessToGoldBuy',
		},
		{
			title: 'Koin Nusantara',
			icon: 'flaticon2-expand',
			page: '/transaction/konus',
			root: true,
			permission: 'accessToKonus',
		  },
        {
          title: 'Gold Sell',
          icon: 'flaticon2-expand',
          page: '/transaction/gold-sell',
          root: true,
          permission: 'accessToGoldSell',
        },
        {
          title: 'Gold Redeem',
          icon: 'flaticon2-expand',
          page: '/transaction/gold-redeem',
          root: true,
          permission: 'accessToGoldRedeem',
        },
        {
          title: 'Users',
          icon: 'flaticon2-expand',
          page: '/user-management/users',
          root: true,
          permission: 'accessToUser',
        },
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
