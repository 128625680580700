import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { GoldSellService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    GoldSellActionTypes,
    GoldSellsPageRequested,
    GoldSellsPageLoaded,
    ManyGoldSellsDeleted,
    OneGoldSellDeleted,
    GoldSellActionToggleLoading,
    GoldSellsPageToggleLoading,
    GoldSellUpdated,
    GoldSellsStatusUpdated,
    GoldSellCreated,
    GoldSellOnServerCreated
} from '../_actions/goldsell.actions';
import { of } from 'rxjs';

@Injectable()
export class GoldSellEffects {
    showPageLoadingDistpatcher = new GoldSellsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new GoldSellActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new GoldSellActionToggleLoading({ isLoading: false });

    @Effect()
    loadGoldSellsPage$ = this.actions$.pipe(
        ofType<GoldSellsPageRequested>(GoldSellActionTypes.GoldSellsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goldsellsService.findGoldSells(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new GoldSellsPageLoaded({
                goldsells: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteGoldSell$ = this.actions$
        .pipe(
            ofType<OneGoldSellDeleted>(GoldSellActionTypes.OneGoldSellDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldsellsService.deleteGoldSell(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteGoldSells$ = this.actions$
        .pipe(
            ofType<ManyGoldSellsDeleted>(GoldSellActionTypes.ManyGoldSellsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldsellsService.deleteGoldSells(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateGoldSell$ = this.actions$
        .pipe(
            ofType<GoldSellUpdated>(GoldSellActionTypes.GoldSellUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldsellsService.updateGoldSell(payload.goldsell);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateGoldSellsStatus$ = this.actions$
        .pipe(
            ofType<GoldSellsStatusUpdated>(GoldSellActionTypes.GoldSellsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldsellsService.updateStatusForGoldSell(payload.goldsells, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createGoldSell$ = this.actions$
        .pipe(
            ofType<GoldSellOnServerCreated>(GoldSellActionTypes.GoldSellOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldsellsService.createGoldSell(payload.goldsell).pipe(
                    tap(res => {
                        this.store.dispatch(new GoldSellCreated({ goldsell: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private goldsellsService: GoldSellService, private store: Store<AppState>) { }
}
