// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { GoldRedeemActions, GoldRedeemActionTypes } from '../_actions/goldredeem.actions';
// Models
import { GoldRedeemModel } from '../_models/goldredeem.model';
import { QueryParamsModel } from '../../_base/crud';

export interface GoldRedeemsState extends EntityState<GoldRedeemModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGoldRedeemId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GoldRedeemModel> = createEntityAdapter<GoldRedeemModel>();

export const initialGoldRedeemsState: GoldRedeemsState = adapter.getInitialState({
    goldredeemForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGoldRedeemId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function goldredeemsReducer(state = initialGoldRedeemsState, action: GoldRedeemActions): GoldRedeemsState {
    switch  (action.type) {
        case GoldRedeemActionTypes.GoldRedeemsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGoldRedeemId: undefined
            };
        }
        case GoldRedeemActionTypes.GoldRedeemActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case GoldRedeemActionTypes.GoldRedeemOnServerCreated: return {
            ...state
        };
        case GoldRedeemActionTypes.GoldRedeemCreated: return adapter.addOne(action.payload.goldredeem, {
            ...state, lastCreatedGoldRedeemId: action.payload.goldredeem.id
        });
        case GoldRedeemActionTypes.GoldRedeemUpdated: return adapter.updateOne(action.payload.partialGoldRedeem, state);
       //  case GoldRedeemActionTypes.GoldRedeemsStatusUpdated: {
       //      const _partialGoldRedeems: Update<GoldRedeemModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.goldredeems.length; i++) {
       //          _partialGoldRedeems.push({
           //  id: action.payload.goldredeems[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialGoldRedeems, state);
       //  }
        case GoldRedeemActionTypes.OneGoldRedeemDeleted: return adapter.removeOne(action.payload.id, state);
        case GoldRedeemActionTypes.ManyGoldRedeemsDeleted: return adapter.removeMany(action.payload.ids, state);
        case GoldRedeemActionTypes.GoldRedeemsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case GoldRedeemActionTypes.GoldRedeemsPageLoaded: {
            return adapter.addMany(action.payload.goldredeems, {
                ...initialGoldRedeemsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getGoldRedeemState = createFeatureSelector<GoldRedeemModel>('goldredeems');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
