// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { KonusActions, KonusActionTypes } from '../_actions/konus.actions';
// Models
import { KonusModel } from '../_models/konus.model';
import { QueryParamsModel } from '../../_base/crud';

export interface KonussState extends EntityState<KonusModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedKonusId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KonusModel> = createEntityAdapter<KonusModel>();

export const initialKonussState: KonussState = adapter.getInitialState({
    konusForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedKonusId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function konussReducer(state = initialKonussState, action: KonusActions): KonussState {
    switch  (action.type) {
        case KonusActionTypes.KonussPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedKonusId: undefined
            };
        }
        case KonusActionTypes.KonusActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case KonusActionTypes.KonusOnServerCreated: return {
            ...state
        };
        case KonusActionTypes.KonusCreated: return adapter.addOne(action.payload.konus, {
            ...state, lastCreatedKonusId: action.payload.konus.id
        });
        case KonusActionTypes.KonusUpdated: return adapter.updateOne(action.payload.partialKonus, state);
       //  case KonusActionTypes.KonussStatusUpdated: {
       //      const _partialKonuss: Update<KonusModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.Konuss.length; i++) {
       //          _partialKonuss.push({
				   //  id: action.payload.Konuss[i].id,
				   //  changes: {
       //                  status: action.payload.status
       //              }
			    // });
       //      }
       //      return adapter.updateMany(_partialKonuss, state);
       //  }
        case KonusActionTypes.OneKonusDeleted: return adapter.removeOne(action.payload.id, state);
        case KonusActionTypes.ManyKonussDeleted: return adapter.removeMany(action.payload.ids, state);
        case KonusActionTypes.KonussPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case KonusActionTypes.KonussPageLoaded: {
            return adapter.addMany(action.payload.konuss, {
                ...initialKonussState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getKonusState = createFeatureSelector<KonusModel>('konuss');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
