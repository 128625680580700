// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { KonusModel } from '../_models/konus.model';

export enum KonusActionTypes {
    KonusOnServerCreated = '[Edit Konus Dialog] Konus On Server Created',
    KonusCreated = '[Edit Konus Dialog] Konus Created',
    KonusUpdated = '[Edit Konus Dialog] Konus Updated',
    KonussStatusUpdated = '[Konus List Page] Konuss Status Updated',
    OneKonusDeleted = '[Konuss List Page] One Konus Deleted',
    ManyKonussDeleted = '[Konuss List Page] Many Konus Deleted',
    KonussPageRequested = '[Konuss List Page] Konuss Page Requested',
    KonussPageLoaded = '[Konuss API] Konuss Page Loaded',
    KonussPageCancelled = '[Konuss API] Konuss Page Cancelled',
    KonussPageToggleLoading = '[Konuss] Konuss Page Toggle Loading',
    KonusActionToggleLoading = '[Konuss] Konuss Action Toggle Loading'
}

export class KonusOnServerCreated implements Action {
    readonly type = KonusActionTypes.KonusOnServerCreated;
    constructor(public payload: { konus: KonusModel }) { }
}

export class KonusCreated implements Action {
    readonly type = KonusActionTypes.KonusCreated;
    constructor(public payload: { konus: KonusModel }) { }
}

export class KonusUpdated implements Action {
    readonly type = KonusActionTypes.KonusUpdated;
    constructor(public payload: {
        partialKonus: Update<KonusModel>, // For State update
        konus: KonusModel // For Server update (through service)
    }) { }
}

export class KonussStatusUpdated implements Action {
    readonly type = KonusActionTypes.KonussStatusUpdated;
    constructor(public payload: {
        konuss: KonusModel[],
        status: number
    }) { }
}

export class OneKonusDeleted implements Action {
    readonly type = KonusActionTypes.OneKonusDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyKonussDeleted implements Action {
    readonly type = KonusActionTypes.ManyKonussDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class KonussPageRequested implements Action {
    readonly type = KonusActionTypes.KonussPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class KonussPageLoaded implements Action {
    readonly type = KonusActionTypes.KonussPageLoaded;
    constructor(public payload: { konuss: KonusModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class KonussPageCancelled implements Action {
    readonly type = KonusActionTypes.KonussPageCancelled;
}

export class KonussPageToggleLoading implements Action {
    readonly type = KonusActionTypes.KonussPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class KonusActionToggleLoading implements Action {
    readonly type = KonusActionTypes.KonusActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type KonusActions = KonusOnServerCreated
| KonusCreated
| KonusUpdated
| KonussStatusUpdated
| OneKonusDeleted
| ManyKonussDeleted
| KonussPageRequested
| KonussPageLoaded
| KonussPageCancelled
| KonussPageToggleLoading
| KonusActionToggleLoading;
